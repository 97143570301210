import { logEvent } from 'firebase/analytics';
import { analytics } from '@app/utils/analytics.ts';
import { OnlineOrderDeliveryMode } from '@app/graphql/types/graphql.ts';

export const analyticProductQuantityIncrease = (props: { productName: string }) => {
  logEvent(analytics, 'increase_quantity', {
    productName: props.productName,
  });
};

export const analyticProductQuantityDecrease = (props: { productName: string }) => {
  logEvent(analytics, 'decrease_quantity', {
    productName: props.productName,
  });
};

export const analyticProductDetailClick = (props: { productName: string }) => {
  logEvent(analytics, 'click_to_detail', {
    productName: props.productName,
  });
};

export const analyticOrderPreview = (props: {
  deliveryMode: OnlineOrderDeliveryMode | null | undefined;
  timeMode: string | null | undefined;
  totalPrice: number | null | undefined;
  deliveryFee: number | null | undefined;
  discountPrice: number | null | undefined;
}) => {
  logEvent(analytics, 'order_preview', {
    ...props,
  });
};
