import { useEffect, useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import CardHeader from '@app/components/card/card-header.tsx';
import Card from '@app/components/card/card.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import SecondaryButton from '@app/components/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import PrimaryButton from '@app/components/primary-button.tsx';

export default function CancelOnlineOrderButton(props: { phone: string; className?: string }) {
  const phone = props.phone;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslate();

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    close();
  }, []);

  return (
    <>
      <button
        className={`w-full flex-1 rounded-md bg-red-500 px-2 py-1.5 text-sm font-semibold text-white hover:bg-red-600 ${props.className ?? ''}`}
        onClick={open}
      >
        {t('online-order.detail-page.cancel.button', 'Stornieren')}
      </button>

      <Dialog onClose={close} open={isOpen} className="relative z-10 focus:outline-none">
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4 text-center backdrop-brightness-75">
            <DialogPanel className="w-full transform overflow-hidden rounded-xl text-left align-middle transition-all sm:max-w-md">
              <Card>
                <CardHeader title={t('online-order.detail-page.cancel.dialog.header', 'Bestellung stornieren')} />
                <CardContent>
                  <label className="mb-2 text-base text-gray-500">
                    {t(
                      'online-order.detail-page.cancel.dialog.label',
                      'Bitte rufen Sie uns an, um Ihre Bestellung zu stornieren.'
                    )}
                  </label>
                  <div className="border-1 mt-4 flex items-center justify-between gap-4 rounded-lg border border-gray-200 p-2">
                    <p className="mt-1">{phone}</p>
                    <a href={`tel:${phone}`}>
                      <SecondaryButton onClick={close}>
                        {t('online-order.detail-page.cancel.dialog.call-button', 'Rufen Sie jetzt an')}
                      </SecondaryButton>
                    </a>
                  </div>
                </CardContent>
                <CardFooter>
                  <PrimaryButton onClick={close}>
                    {t('online-order.detail-page.cancel.dialog.confirm-button', 'Done')}
                  </PrimaryButton>
                </CardFooter>
              </Card>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
