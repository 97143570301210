import { graphql } from '@app/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query storefront_isOnlineOrderOpen($branchID: ID!) {
    storefront_isOnlineOrderOpen(branchID: $branchID) {
      isDeliveryAvailable
      isPickupAvailable
    }
  }
`);

export default function useOnlineOrderOpen(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchID: branchId,
    },
  });
}
