import classNames from 'classnames';

export default function DualItem(props: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={classNames('flex grid grid-cols-1 items-center justify-center gap-4 xl:grid-cols-2', props.className)}
    >
      {props.children}
    </div>
  );
}
