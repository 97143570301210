import { useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { DisabledTimes, IntRange } from 'rc-picker/lib/interface';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import {
  BranchItemFragment,
  DaysOfWeek,
  OnlineOrderBranchConfigurationItemFragment,
  OpeningTimeServiceType,
} from '@app/graphql/types/graphql.ts';
import { useGetOpenningTime } from '@app/page/appointment/appointment-create/logic/use-get-openning-time.ts';
import Loading from '@app/components/loading.tsx';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useHolidays from '@app/page/appointment/appointment-create/logic/use-holidays.ts';
import { todayIs } from '@app/page/online-order/product-picker/utils/today.ts';

dayjs.extend(utc);
dayjs.extend(timezone);

const PAUSE = false;
const HOUR_BLOCKING = 6;
const TRAIT_MINUTES = 0;

const OnlineOrderDateInput = (props: {
  branch: BranchItemFragment;
  currentValue?: string;
  handleFunction: (value: string) => void;
  type: OpeningTimeServiceType.OnlineOrderDelivery | OpeningTimeServiceType.OnlineOrderPickup;
  onlineOrderBranchConfiguration: OnlineOrderBranchConfigurationItemFragment;
  isCloseNow: boolean;
}) => {
  const { handleFunction } = props;
  const { data, loading } = useGetOpenningTime(props.branch.id, props.type);
  // Chỉ lấy dữ liệu timezone
  const { data: holidayData } = useHolidays(props.branch.id);

  // @ts-expect-error wrong type from library
  const minuteStep: IntRange<1, 59> = 60 / HOUR_BLOCKING;

  const [currentTimezone, setTimezone] = useState<string>('Europe/Berlin');
  const [date, setDate] = useState<Dayjs>(dayjs());

  const [disabledTime, setDisabledTime] = useState<((date: Dayjs) => DisabledTimes) | undefined>();

  const timeHandler = (time: string) => {
    const [hour, minute] = time.split(':');

    if (!hour || !minute) {
      return;
    }

    setDate(date.set('hour', parseInt(hour)).set('minute', parseInt(minute)));

    handleFunction(date.set('hour', parseInt(hour)).set('minute', parseInt(minute)).format('YYYY-MM-DDTHH:mm'));
  };

  useEffect(() => {
    setTimezone(props.branch.company.settings.timezone);

    const today = todayIs();
    const earliestOpeningTime = props.branch.openingTimes
      ?.filter((item) => item.dayOfWeek == today && item.service == props.type)
      .sort((a, b) => {
        if (a.openTime < b.openTime) return -1;
        if (a.openTime > b.openTime) return 1;
        return 0;
      })[0]?.openTime;

    if (!earliestOpeningTime) {
      return;
    }

    if ((props.currentValue == '' || !props.currentValue) && props.isCloseNow) {
      setDate(
        dayjs()
          .tz(props.branch.company.settings.timezone)
          .set('hour', Number(earliestOpeningTime.slice(0, 2)))
          .set('minute', Number(earliestOpeningTime.slice(3)))
      );
      return;
    }

    if (!props.currentValue) {
      setDate(
        dayjs()
          .tz(props.branch.company.settings.timezone)
          .add(1, 'hour')
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0)
      );
      return;
    }

    if (props.currentValue) {
      setDate(
        dayjs()
          .tz(props.branch.company.settings.timezone)
          .set('date', Number(props.currentValue.slice(8, 10)))
          .set('hour', Number(props.currentValue.slice(11, 13)))
          .set('minute', Number(props.currentValue.slice(14, 16)))
          .set('millisecond', 0)
      );
      return;
    }
  }, [
    data,
    setDate,
    props.currentValue,
    props.branch.company.settings.timezone,
    props.branch.openingTimes,
    props.isCloseNow,
    props.type,
  ]);

  useEffect(() => {
    let onlineOrderPreparingTime = 0;

    if (
      props.type == OpeningTimeServiceType.OnlineOrderPickup &&
      props.onlineOrderBranchConfiguration.autoPickupConfirm
    ) {
      onlineOrderPreparingTime = props.onlineOrderBranchConfiguration.defaultPreparingTime;
    }

    if (
      props.type == OpeningTimeServiceType.OnlineOrderDelivery &&
      props.onlineOrderBranchConfiguration.autoDeliveryConfirm
    ) {
      if (props.onlineOrderBranchConfiguration.deliveryTableData) {
        const sortedDeliveryTableDataByTime = [...props.onlineOrderBranchConfiguration.deliveryTableData].sort(
          (a, b) => {
            return a.deliveryTime - b.deliveryTime;
          }
        );

        const minDeliveryTime = sortedDeliveryTableDataByTime.at(0);
        onlineOrderPreparingTime = minDeliveryTime ? minDeliveryTime.deliveryTime : onlineOrderPreparingTime;
      }
    }

    const reservationTraitMinutes = TRAIT_MINUTES;
    const time = dayjs.tz(dayjs(), currentTimezone);
    const isToday = moment(date.toISOString()).tz(currentTimezone).diff(moment.tz(currentTimezone), 'day') == 0;

    const fc = (date: Dayjs): DisabledTimes => {
      const holidays =
        holidayData?.storefront_holidays.filter((holiday) => {
          const startTime = moment(holiday.startTime).tz(currentTimezone).format('YYYY-MM-DD');

          const endTime = moment(holiday.endTime).tz(currentTimezone).format('YYYY-MM-DD');
          const today = moment(date.toISOString()).tz(currentTimezone).format('YYYY-MM-DD');

          if (moment(today).isBetween(startTime, endTime, undefined, '[]')) {
            return true;
          }
        }) ?? [];

      const openingTimeToday =
        data?.storefront_openingTimes.filter((op) => {
          return op.dayOfWeek === currentDayOfWeek(date);
        }) ?? [];

      // Disable all time if pause is true and today
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (PAUSE && isToday) {
        return {
          disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
          disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
        };
      }

      // Disable all time if no opening time today
      return {
        disabledHours: () => {
          let bannedHours = Array.from({ length: 24 }, (_, i) => i);

          for (const openingTime of openingTimeToday) {
            bannedHours = bannedHours.filter((hour) => {
              const openTime = time
                .set('hour', parseInt(openingTime.openTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.openTime.slice(3, 5)));
              const closeTime = time
                .set('hour', parseInt(openingTime.closeTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.closeTime.slice(3, 5)));

              let openHour = parseInt(openingTime.openTime.slice(0, 2));
              const closeHour = closeTime.subtract(reservationTraitMinutes, 'minutes').hour();

              if (isToday) {
                if ((time < openTime && openTime.diff(time, 'minute') < onlineOrderPreparingTime) || time >= openTime) {
                  openHour = time.add(onlineOrderPreparingTime, 'minutes').hour();
                }
              }

              return hour < openHour || hour > closeHour;
            });
          }

          for (const holiday of holidays) {
            const startTime = moment(holiday.startTime).tz(currentTimezone);
            const endTime = moment(holiday.endTime).tz(currentTimezone);

            const openHour = startTime.get('hour');
            const closeHour = endTime.get('hour');
            const holidayBannedHours = Array.from({ length: 24 }, (_, i) => i);

            const holidayBannedHoursFilter = holidayBannedHours.filter((item) => item > openHour && item < closeHour);
            holidayBannedHoursFilter.forEach((item) => {
              if (!bannedHours.includes(item)) {
                bannedHours.push(item);
              }
            });
          }

          return bannedHours;
        },
        disabledMinutes: (hour: number) => {
          let bannedMinutes = Array.from({ length: 60 }, (_, i) => i);

          for (const openingTime of openingTimeToday) {
            bannedMinutes = bannedMinutes.filter((bannedHour) => {
              const openTime = time
                .set('hour', parseInt(openingTime.openTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.openTime.slice(3, 5)));
              const closeTime = time
                .set('hour', parseInt(openingTime.closeTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.closeTime.slice(3, 5)));

              let openHour = parseInt(openingTime.openTime.slice(0, 2));
              const closeHour = closeTime.subtract(reservationTraitMinutes, 'minutes').hour();

              let openMinute = parseInt(openingTime.openTime.slice(3, 5));
              const closeMinute = closeTime.subtract(reservationTraitMinutes, 'minutes').minute();

              if (isToday) {
                if ((time < openTime && openTime.diff(time, 'minute') < onlineOrderPreparingTime) || time >= openTime) {
                  openHour = time.add(onlineOrderPreparingTime, 'minutes').hour();

                  openMinute = time.add(onlineOrderPreparingTime, 'minutes').minute();
                }
              }
              return (
                hour < openHour ||
                hour > closeHour ||
                (hour === openHour && bannedHour < openMinute) ||
                (hour === closeHour && bannedHour > closeMinute)
              );
            });
          }

          for (const holiday of holidays) {
            const startTime = moment(holiday.startTime).tz(currentTimezone);
            const endTime = moment(holiday.endTime).tz(currentTimezone);

            const openHour = startTime.get('hour');
            const closeHour = endTime.get('hour');

            const openMinute = startTime.get('minutes');
            const closeMinute = endTime.get('minutes');

            const holidayBannedMinutes = Array.from({ length: 60 }, (_, i) => i);

            const holidayBannedMinutesFilter1 = holidayBannedMinutes.filter((item) => item >= openMinute);
            const holidayBannedMinutesFilter2 = holidayBannedMinutes.filter((item) => item < closeMinute);

            if (hour == openHour) {
              holidayBannedMinutesFilter1.forEach((item) => {
                bannedMinutes.push(item);
              });
            }

            if (hour == closeHour) {
              holidayBannedMinutesFilter2.forEach((item) => {
                bannedMinutes.push(item);
              });
            }
          }

          return bannedMinutes;
        },
      };
    };

    setDisabledTime(() => fc);
  }, [
    data?.storefront_openingTimes,
    currentTimezone,
    holidayData?.storefront_holidays,
    date,
    props.onlineOrderBranchConfiguration.autoDeliveryConfirm,
    props.onlineOrderBranchConfiguration.autoPickupConfirm,
    props.onlineOrderBranchConfiguration.defaultPreparingTime,
    props.onlineOrderBranchConfiguration.deliveryTableData,
    props.type,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="p-4">
        <TimeSlots
          value={date}
          disabledDate={disabledTime}
          minuteStep={minuteStep}
          onTimeChange={(time) => {
            timeHandler(time);
          }}
        />
      </div>
    </div>
  );
};

const TimeSlots = (props: {
  value: Dayjs;
  disabledDate: ((date: Dayjs) => DisabledTimes) | undefined;
  onTimeChange?: (time: string) => void;
  minuteStep: number;
}) => {
  const { value, disabledDate, minuteStep } = props;
  const disabledTimes = disabledDate ? disabledDate(value) : undefined;

  const { t } = useTranslate();
  // Generate all time slots & filter
  const timeSlots: string[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += minuteStep) {
      // Check disable time
      if (disabledTimes) {
        const disabledHours = disabledTimes.disabledHours?.() ?? [];
        const disabledMinutes = disabledTimes.disabledMinutes?.(i) ?? [];

        if (disabledHours.includes(i) || disabledMinutes.includes(j)) {
          continue;
        } else {
          timeSlots.push(`${formatHour(i)}:${formatMinute(j)}`);
        }
      } else {
        timeSlots.push(`${formatHour(i)}:${formatMinute(j)}`);
      }
    }
  }

  if (timeSlots.length === 0) {
    return (
      <div className="text-sm text-gray-400">
        {t('storefront-appointment.create.service-and-time.form-input.date.no-time', 'No available time')}
      </div>
    );
  }

  return (
    <div className="grid max-h-48 grid-cols-3 gap-2 overflow-scroll rounded-xl bg-neutral-200 p-6 [&::-webkit-scrollbar]:w-0">
      {timeSlots.map((time) => {
        return (
          <div
            key={time}
            onClick={() => props.onTimeChange?.(time)}
            className={classNames(
              'flex cursor-pointer items-center justify-center rounded-lg px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-indigo-600 hover:text-white',
              {
                'bg-indigo-600 text-white hover:bg-indigo-400': value.format('HH:mm') === time,
              }
            )}
          >
            {time}
          </div>
        );
      })}
    </div>
  );
};

function formatHour(hour: number): string {
  return hour < 10 ? `0${String(hour)}` : String(hour);
}

function formatMinute(minute: number): string {
  return minute < 10 ? `0${String(minute)}` : String(minute);
}

function currentDayOfWeek(date: Dayjs) {
  const daysOfWeeks: DaysOfWeek[] = [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
  ];
  return daysOfWeeks[date.day()];
}

export default OnlineOrderDateInput;
