import { graphql } from '@app/graphql/types';
import { useQuery } from '@apollo/client';

export const QUERY = graphql(`
  query OnlineOrderDetailPage($id: ID!) {
    storefront_onlineOrder(id: $id) {
      ...OnlineOrderItem
    }

    storefront_onlineOrderBranchConfigurationByOrderID(id: $id) {
      ...OnlineOrderBranchConfigurationItem
    }
  }
`);

export function useOnlineOrderDetailPageQuery(id: string | undefined) {
  return useQuery(QUERY, { variables: { id: id ?? '' }, skip: !id, pollInterval: 5000 });
}
