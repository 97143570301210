import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import classNames from 'classnames';

export default function AlertBanner(props: { title: string; message: string }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      className={classNames(
        'sticky inset-x-0 top-0 z-50 flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1',
        !isOpen && 'hidden'
      )}
    >
      {props.title && (
        <p className="text-sm leading-6 text-white">
          <a href="#">
            <strong className="font-semibold">{props.title}</strong>
            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
              <circle r={1} cx={1} cy={1} />
            </svg>
            {props.message}
          </a>
        </p>
      )}
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          <XMarkIcon
            onClick={() => {
              setIsOpen(false);
            }}
            aria-hidden="true"
            className="h-5 w-5 text-white"
          />
        </button>
      </div>
    </div>
  );
}
