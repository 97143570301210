import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@app/components/card/card-content.tsx';
import DescriptionItem from '@app/components/description/description-item.tsx';
import DualItem from '@app/components/form/dual-form-item.tsx';
import {
  BranchItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderConfirmationState,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
} from '@app/graphql/types/graphql.ts';
import moment from 'moment-timezone';

export default function ContactAndAddressInfo(props: {
  onlineOrder: OnlineOrderItemFragment;
  branch: BranchItemFragment;
  configuration: OnlineOrderBranchConfigurationItemFragment;
}) {
  const { t } = useTranslate();

  const address =
    props.onlineOrder.deliveryMode == OnlineOrderDeliveryMode.Delivery
      ? `${props.onlineOrder.addressLocality ?? ''}, ${props.onlineOrder.streetAddress ?? ''}, ${props.onlineOrder.postalCode ?? ''}, ${props.onlineOrder.addressCountry ?? ''}`
      : undefined;

  // const date = moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('YYYY-MM-DD');

  const time = moment.tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone).format('HH:mm');

  const guestName = props.onlineOrder.guestName ?? '';

  const email = props.onlineOrder.email ?? '';

  const phone = props.onlineOrder.phone ?? '';

  const message = props.onlineOrder.message ?? '';

  const estimatedTime =
    props.onlineOrder.confirmationState !== OnlineOrderConfirmationState.Pending
      ? props.onlineOrder.expectedTime
        ? moment
            .tz(props.onlineOrder.expectedTime, props.branch.company.settings.timezone)
            .add(props.onlineOrder.estimatedDuration, 'minutes')
            .format('HH:mm')
        : moment
            .tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone)
            .add(props.onlineOrder.estimatedDuration, 'minutes')
            .format('HH:mm')
      : null;

  const expectedTime = props.onlineOrder.expectedTime
    ? moment.tz(props.onlineOrder.expectedTime, props.branch.company.settings.timezone).format('HH:mm')
    : null;

  return (
    <Card>
      <CardHeader title={t('online-order.review-page.contact-info.header', 'Contact Data & Delivery Address')} />

      <CardContent>
        <div className="space-y-6">
          {address && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.address', 'Address')}
              value={address}
            />
          )}
          <DualItem className={'gap-0 space-y-10'}>
            {/*<DescriptionItem*/}
            {/*  label={t('online-order.review-page.review-side-bar.your-contact.date', 'Date')}*/}
            {/*  value={date}*/}
            {/*/>*/}

            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.time', 'Created time')}
              value={time}
            />
          </DualItem>
          {expectedTime && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.expected-time', 'Expected time')}
              value={expectedTime}
            />
          )}

          {estimatedTime && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.completion-time', 'Completion time')}
              value={estimatedTime}
            />
          )}
          <DescriptionItem
            label={t('online-order.review-page.review-side-bar.your-contact.name', 'Guest name')}
            value={guestName}
          />
          <DualItem className={'flex gap-y-6'}>
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.email', 'Email')}
              value={email}
            />
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.phone-number', 'Phone number')}
              value={phone}
            />
          </DualItem>
          {message !== '' && (
            <DescriptionItem
              label={t('online-order.review-page.review-side-bar.your-contact.message', 'Message')}
              value={message}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
