import { create } from 'zustand';
import {
  BranchItemFragment,
  PickedProductInput,
  Storefront_CreateAppointmentInput,
} from '@app/graphql/types/graphql.ts';
import * as yup from 'yup';

// Name base on figma monitors
export enum AppointmentMonitor {
  start = 'START',
  time = 'TIME',
  selection = 'SELECTION',
  contactInfo = 'CONTACT_INFO',
}

interface AppointmentUpdateInput {
  email?: string;
  message?: string;
  name?: string;
  phone?: string;
  startTime?: string;
  receiveNewsletterPerEmail?: boolean;
}

interface AppointmentState {
  currentMonitor: AppointmentMonitor;
  branch: BranchItemFragment | null;
  pickedProduct: PickedProductInput | null;
  memberId: string | null;
  appointmentInput: Storefront_CreateAppointmentInput;
  errors: Record<string, string>;
}

interface AppointmentAction {
  selectProduct: (product: PickedProductInput) => void;
  selectMember: (memberId: string | null) => void;

  setAppointmentInput: (appointmentInput: AppointmentUpdateInput) => void;
  setBranch: (branch: BranchItemFragment) => void;
  setAppointmentMonitor: (monitor: AppointmentMonitor) => void;
  setErrors: (errors: Record<string, string>) => void;
  reset: () => void;
}

const initialState: AppointmentState = {
  currentMonitor: AppointmentMonitor.start,
  branch: null,
  pickedProduct: null,
  memberId: null,
  appointmentInput: {
    email: '',
    message: '',
    name: '',
    phone: '',
    startTime: '',
    receiveNewsletterPerEmail: false,
  },
  errors: {},
};
export const useAppointmentInfo = create<AppointmentState & AppointmentAction>()((set, get) => ({
  ...initialState,

  selectProduct: (product: PickedProductInput) => {
    set(() => ({ pickedProduct: product }));
  },

  selectMember: (memberId: string | null) => {
    if (memberId) {
      set(() => ({ memberId }));
    } else {
      set(() => ({ memberId: null }));
    }
  },

  setAppointmentInput: (appointmentUpdateInput: AppointmentUpdateInput) => {
    set({ appointmentInput: { ...get().appointmentInput, ...appointmentUpdateInput } });
  },

  setBranch: (branchParam: BranchItemFragment) => {
    set({ branch: branchParam });
  },

  setAppointmentMonitor: (monitor: AppointmentMonitor) => {
    set({ currentMonitor: monitor });
  },

  setErrors: (errors: Record<string, string>) => {
    set({ errors });
  },

  reset: () => {
    set(initialState);
  },
}));

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('storefront-appointment.create.contact-info.validate.name.required')
    .max(50, 'storefront-appointment.create.contact-info.validate.name.max-length'),
  email: yup
    .string()
    .email('storefront-appointment.create.contact-info.validate.email.valid')
    .required('storefront-appointment.create.contact-info.validate.email.required'),
  phone: yup.string().required('storefront-appointment.create.contact-info.validate.phone.required'),
  startTime: yup.string().required('storefront-appointment.create.time.validate.start-time.required'),
});

export const validateInputs = async (appointmentInput: Storefront_CreateAppointmentInput) => {
  try {
    await validationSchema.validate(appointmentInput, { abortEarly: false });
    useAppointmentInfo.getState().setErrors({});
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const validationErrors: Record<string, string> = {};
      err.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path] = error.message;
        }
      });
      useAppointmentInfo.getState().setErrors(validationErrors);
    }
    return false;
  }
};

const timeValidationSchema = yup.object().shape({
  startTime: yup.string().required('storefront-appointment.create.time.validate.start-time.required'),
});

export const validateTimeInput = async (appointmentInput: Storefront_CreateAppointmentInput) => {
  try {
    await timeValidationSchema.validate(appointmentInput, { abortEarly: false });
    useAppointmentInfo.getState().setErrors({});
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const validationErrors: Record<string, string> = {};
      err.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path] = error.message;
        }
      });
      useAppointmentInfo.getState().setErrors(validationErrors);
    }
    return false;
  }
};
