import {
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
} from '@app/graphql/types/graphql.ts';
import CurrencyView from '@app/components/price/currency-view.tsx';
import { OnlineOrderProductsFragment } from '@app/page/online-order/model/online-order-fragment.ts';
import { getFragmentData } from '@app/graphql/types';
import DialogButton from '@app/components/dialog-button.tsx';
import ModifyProductCart from '@app/page/online-order/fill-contact-info/component/modify-product-cart.tsx';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import { useTranslate } from '@tolgee/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardTableContent from '@app/components/card/card-content-table.tsx';
import ListItem from '@app/components/list/list-item.tsx';
import { ReactNode } from 'react';

const CartSideBarPreview = (props: {
  order: OnlineOrderItemFragment;
  mode: CartSideBarPreviewMode;
  onlineOrderBranchConfiguration?: OnlineOrderBranchConfigurationItemFragment;
  deliveryMode?: OnlineOrderDeliveryMode;
  editable?: boolean;
  headerChildren?: ReactNode;
}) => {
  const { mode } = props;
  const editable = props.editable ?? true;

  const products = getFragmentData(OnlineOrderProductsFragment, props.order.onlineOrderProducts);

  const sortedProducts = [...products].sort((a, b) => {
    return a.id.localeCompare(b.id);
  });

  const { t } = useTranslate();
  return (
    <Card>
      <CardHeader title={t('online-order.review-page.cart-side-bar-preview.order-summary', 'Order summary')}>
        {props.headerChildren}
      </CardHeader>

      <CardTableContent>
        {sortedProducts.map((pickedProduct) => {
          const configurationText = pickedProduct.configurations
            .map((config) => {
              return config.value;
            })
            .join(', ');

          const productPrice = pickedProduct.configurations.reduce((acc, config) => {
            return acc + config.price;
          }, 0);

          const price = productPrice * pickedProduct.quantity;

          if (!pickedProduct.menuProduct) {
            return;
          }

          let discountable = false;
          const deliveryMode = props.deliveryMode || props.order.deliveryMode;

          if (deliveryMode === OnlineOrderDeliveryMode.Delivery || deliveryMode === OnlineOrderDeliveryMode.Pickup) {
            discountable = pickedProduct.discountable ?? true;
          }

          return (
            <ListItem
              key={pickedProduct.id}
              sideContent={
                <div className="flex-shrink-0">
                  <div className="flex flex-col items-end text-wrap text-sm sm:flex-row">
                    <p className="mr-1 truncate text-gray-400">
                      <span>
                        <CurrencyView price={productPrice} />
                      </span>
                      <span>{`x ${pickedProduct.quantity.toString()} `}</span>
                      <span className={'hidden sm:inline'}>=</span>
                    </p>

                    <CurrencyView price={price} />
                  </div>
                </div>
              }
              sideContentAlign={'top'}
            >
              <div className="item-start flex gap-6 px-2">
                <div className="flex flex-col justify-between">
                  {/*Title*/}
                  <div className="line-clamp-2 font-medium">{pickedProduct.title}</div>
                  {/*Description*/}
                  <div className="line-clamp-2 flex flex-wrap space-x-1 text-sm text-gray-400">{configurationText}</div>

                  {editable && (
                    <DialogButton content={<ModifyProductCart onlineOrderProduct={pickedProduct} />}>
                      <div className="text-gtay-700 text-sm underline">Edit</div>
                    </DialogButton>
                  )}
                </div>

                <p className="-ml-4 h-full text-sm text-red-500">
                  {!discountable && t('online-order.discounted', '(No Discounted)')}
                </p>
              </div>
            </ListItem>
          );
        })}

        {mode === CartSideBarPreviewMode.OnlyProducts && (
          <ListItem>
            <div className="flex items-center justify-end">
              <p className="tex-gray-600 text-lg font-medium">
                {t('online-order.review-page.cart-side-bar-preview.subtotal', 'Subtotal')}
              </p>
              <div className="flex-grow" />
              <CurrencyView className="ml-2 text-xl font-medium" price={props.order.basePrice} />
            </div>
          </ListItem>
        )}

        {mode === CartSideBarPreviewMode.Full && (
          <>
            <ListItem>
              <PriceRow
                label={t('online-order.review-page.cart-side-bar-preview.subtotal', 'Subtotal')}
                price={props.order.basePrice ?? 0}
              />
            </ListItem>

            {props.order.deliveryPrice && (
              <ListItem>
                <PriceRow
                  label={t('online-order.review-page.cart-side-bar-preview.delivery-fees', 'Delivery fee')}
                  price={props.order.deliveryPrice}
                />
              </ListItem>
            )}

            {props.order.deliveryDiscount && props.order.deliveryDiscount < 0 ? (
              <ListItem>
                <PriceRow
                  label={t('online-order.review-page.cart-side-bar-preview.delivery-discount', 'Delivery discount')}
                  price={props.order.deliveryDiscount}
                />
              </ListItem>
            ) : null}

            {props.order.pickupDiscount && props.order.pickupDiscount < 0 ? (
              <ListItem>
                <PriceRow
                  label={t('online-order.review-page.cart-side-bar-preview.pickup-discount', 'Pickup discount')}
                  price={props.order.pickupDiscount}
                />
              </ListItem>
            ) : null}

            <ListItem>
              <div className="flex w-full items-end justify-end">
                <p className="text-lg font-semibold">
                  {t('online-order.review-page.cart-side-bar-preview.total-price', 'Total price')}
                </p>
                <div className="flex-grow" />
                <CurrencyView className="text-2xl font-medium" price={props.order.totalPrice} />
              </div>
            </ListItem>
          </>
        )}
      </CardTableContent>
    </Card>
  );
};

const PriceRow = (props: { label: string; price?: number; info?: string }) => {
  return (
    <div className="flex justify-between">
      <p className="text-gray-500">{props.label}</p>
      {props.info && <p className="text-gray-500">{props.info}</p>}
      {props.price && <CurrencyView price={props.price} />}
    </div>
  );
};

export default CartSideBarPreview;
