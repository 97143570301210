import { graphql } from '@app/graphql/types';

export const OnlineOrderBranchConfigurationFragment = graphql(`
  fragment OnlineOrderBranchConfigurationItem on Storefront_OnlineOrderBranchConfiguration {
    branchID
    menu {
      ...MenuItem
    }
    deliveryTableData {
      id
      deliveryDistance
      deliveryTime
      deliveryFee
    }
    defaultPreparingTime
    categoryStyle
    pickupDiscountAmount
    pickupDiscountType
    deliveryDiscountAmount
    deliveryDiscountType
    autoDeliveryConfirm
    autoPickupConfirm
    pickupEnable
    deliveryEnable
    minimumOrderAmountForDelivery
  }
`);
