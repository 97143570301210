export default function ListLayout(props: {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
  id?: string;
  header?: React.ReactNode;
}) {
  return (
    <div className={props.className} id={props.id}>
      <div className="border-b bg-gray-50 px-4 py-2">
        <div className="flex flex-col text-lg font-semibold text-gray-500 sm:flex-row sm:items-center sm:justify-between">
          {props.title}
          {props.header}
        </div>
        {props.subtitle && (
          <p className="max-w-44 truncate text-sm text-gray-500 sm:max-w-60 md:max-w-md xl:max-w-xl">
            {props.subtitle}
          </p>
        )}
      </div>
      <div>{props.children}</div>
    </div>
  );
}
