import { useEffect, useState } from 'react';
import { useTolgee } from '@tolgee/react';
import { captureException } from '@sentry/react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

//with name of country
export default function LanguageSelectorAdvanced(props: { customerDefaultLanguage?: string }) {
  const [selected, setSelected] = useState<{ id: number; language: string; languageCode: string; country: string }>(
    languages[0]
  );
  const { changeLanguage } = useTolgee(['language']);

  useEffect(() => {
    if (!props.customerDefaultLanguage) {
      return;
    }

    localStorage.setItem('language', props.customerDefaultLanguage);
    setSelected(languages.find((language) => language.languageCode == props.customerDefaultLanguage) ?? languages[0]);
    changeLanguage(props.customerDefaultLanguage).catch(captureException);
  }, [changeLanguage, props.customerDefaultLanguage]);

  const handleChange = (selectedLanguage: (typeof languages)[0]) => {
    setSelected(selectedLanguage);
    changeLanguageCallback(selectedLanguage.languageCode);
  };

  const changeLanguageCallback = (lng: string) => {
    changeLanguage(lng).catch(captureException);
    localStorage.setItem('language', lng);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="relative mt-2">
        <ListboxButton className="relative w-auto cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span className="absolute inset-y-0 left-0 m-1 flex items-center pl-2 pr-2">
            <img
              src={`https://flagcdn.com/16x12/${selected.country}.png`}
              width="20"
              height="16"
              alt={selected.country}
            />
          </span>
          <span className="block truncate pl-7">{selected.language}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </span>
        </ListboxButton>

        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {languages.map((lang) => (
            <ListboxOption
              key={lang.id}
              value={lang}
              className="relative m-1 cursor-default select-none py-2 pl-8 pr-4 text-gray-900 hover:bg-indigo-600 hover:text-white"
            >
              <span className="absolute inset-y-0 left-0 m-1 flex items-center pl-2">
                <img src={`https://flagcdn.com/16x12/${lang.country}.png`} width="20" height="16" alt={lang.country} />
              </span>
              <span className="pl-2">{lang.language}</span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

const languages = [
  { id: 1, language: 'English', languageCode: 'en', country: 'us' },
  { id: 2, language: 'Germany', languageCode: 'de', country: 'de' },
  { id: 3, language: 'Vietnamese', languageCode: 'vi', country: 'vn' },
];
