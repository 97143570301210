import { BranchItemFragment, ServiceProductItemFragment } from '@app/graphql/types/graphql.ts';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import SelectedProduct from '@app/page/appointment/appointment-create/component/selected-product.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import {
  AppointmentMonitor,
  useAppointmentInfo,
  validateInputs,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import moment from 'moment-timezone';
import FormLayout from '@app/components/form/form-layout.tsx';
import FormItem from '@app/components/form/form-item.tsx';
import TextInput from '@app/components/form/input/text-input.tsx';
import PhoneInput from 'react-phone-number-input/min';
import { useTranslate } from '@tolgee/react';
import { captureException } from '@sentry/react';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';
import CheckBoxInput from '@app/components/form/input/check-box-input.tsx';

export default function AppointmentServiceContactInfo(props: {
  branch: BranchItemFragment;
  selectedProduct: ServiceProductItemFragment | null | undefined;
  handleSubmit: () => void;
}) {
  const { t } = useTranslate();
  const { selectedProduct, handleSubmit } = props;
  const appointmentInput = useAppointmentInfo((state) => state.appointmentInput);
  const setAppointmentInput = useAppointmentInfo((state) => state.setAppointmentInput);
  const setAppointmentMonitor = useAppointmentInfo((state) => state.setAppointmentMonitor);
  const errors = useAppointmentInfo((state) => state.errors);

  const handleBackButtonClick = () => {
    setAppointmentMonitor(AppointmentMonitor.time);
  };

  const handleValidateAndSubmit = () => {
    validateInputs(appointmentInput)
      .then((isValid) => {
        if (isValid) {
          handleSubmit();
        }
      })
      .catch((err) => {
        captureException(err);
      });
  };

  return (
    <div className="flex w-full flex-col items-center bg-gray-100 sm:justify-center">
      <Card className="w-full sm:mt-24 md:mt-12 lg:mx-auto lg:mt-24 lg:w-[850px]">
        <CardHeader
          title={t('storefront-appointment.create.contact-info.header', 'Back to service and time')}
          withBackButton={true}
          handleBackButtonClick={handleBackButtonClick}
        >
          <LanguageSelector />
        </CardHeader>

        <CardContent>
          <div className="m-2 flex flex-col gap-8">
            <div className="-mx-2">
              <SelectedProduct selectedProduct={selectedProduct} />
            </div>

            {/*Start time info*/}
            <div className="grid grid-cols-2 gap-4 border-b pb-4">
              <div className="col-span-2 space-y-1 sm:col-span-1">
                <p className="text-sm">
                  {t('storefront-appointment.create.contact-info.form-input.date.title', 'Date')}
                </p>
                {appointmentInput.startTime ? (
                  <p>{moment(appointmentInput.startTime).format('ddd DD MMM')}</p>
                ) : (
                  <p>{t('storefront-appointment.create.contact-info.form-input.time.no-valid', 'No time selected')}</p>
                )}
                {errors.startTime && <p className="text-sm text-red-500">{t(errors.startTime)}</p>}
              </div>
              <div className="col-span-2 space-y-1 sm:col-span-1">
                <p className="text-sm">
                  {t('storefront-appointment.create.contact-info.form-input.time.title', 'Time')}
                </p>
                {appointmentInput.startTime ? (
                  <p>{moment(appointmentInput.startTime).format('HH:mm')}</p>
                ) : (
                  <p>{t('storefront-appointment.create.contact-info.form-input.time.no-valid', 'No time selected')}</p>
                )}
                {errors.startTime && <p className="text-sm text-red-500">{t(errors.startTime)}</p>}
              </div>
            </div>

            <FormLayout>
              <div className="grid grid-cols-2 gap-x-4 gap-y-8">
                <FormItem
                  title={t('storefront-appointment.create.contact-info.form-input.name.title', 'Name')}
                  className={'col-span-2 sm:col-span-1'}
                >
                  <TextInput
                    type={'text'}
                    label={'name'}
                    value={appointmentInput.name}
                    name={'name'}
                    placeholder={t(
                      'storefront-appointment.create.contact-info.form-input.name.placeholder',
                      'Type your name'
                    )}
                    onChange={(e) => {
                      setAppointmentInput({ name: e.target.value });
                    }}
                    autoComplete={'on'}
                  />
                  {errors.name && <p className="text-sm text-red-500">{t(errors.name)}</p>}
                </FormItem>

                <div className="hidden sm:block"></div>

                <FormItem
                  title={t('storefront-appointment.create.contact-info.form-input.email.title', 'Email')}
                  className={'col-span-2 sm:col-span-1'}
                >
                  <TextInput
                    type={'email'}
                    label={'email'}
                    value={appointmentInput.email}
                    name={'email'}
                    placeholder={t(
                      'storefront-appointment.create.contact-info.form-input.email.placeholder',
                      'example@gmail.com'
                    )}
                    onChange={(e) => {
                      setAppointmentInput({ email: e.target.value });
                    }}
                    autoComplete={'on'}
                  />
                  {errors.email && <p className="text-sm text-red-500">{t(errors.email)}</p>}
                </FormItem>

                <FormItem
                  title={t('storefront-appointment.create.contact-info.form-input.phone.title', 'Phone number')}
                  className={'col-span-2 sm:col-span-1'}
                >
                  {/*  <TextInput*/}
                  {/*    type={'text'}*/}
                  {/*    label={'name'}*/}
                  {/*    value={appointmentInput.phone}*/}
                  {/*    name={'name'}*/}
                  {/*    placeholder={'Type phone number'}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      setAppointmentInput({ phone: e.target.value });*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  {errors.phone && <p className="text-sm text-red-500">{errors.phone}</p>}*/}

                  <PhoneInput
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultCountry={'DE'}
                    placeholder={t(
                      'storefront-appointment.create.contact-info.form-input.phone.placeholder',
                      'Enter phone number'
                    )}
                    value={appointmentInput.phone}
                    onChange={(value) => {
                      setAppointmentInput({ phone: value });
                    }}
                  />
                  {errors.phone && <p className="text-sm text-red-500">{t(errors.phone)}</p>}
                </FormItem>

                <FormItem
                  title={t('storefront-appointment.create.contact-info.form-input.message.title', 'Message')}
                  className={'col-span-2 sm:col-span-1'}
                >
                  <textarea
                    id="message"
                    name="message"
                    rows={3}
                    value={appointmentInput.message}
                    onChange={(e) => {
                      setAppointmentInput({ message: e.target.value });
                    }}
                    className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.message && <p className="text-sm text-red-500">{t(errors.message)}</p>}
                </FormItem>
              </div>

              <CheckBoxInput
                onChange={(_, value) => {
                  setAppointmentInput({ receiveNewsletterPerEmail: value });
                }}
                value={appointmentInput.receiveNewsletterPerEmail ?? false}
                name={t(
                  'storefront-appointment.create.contact-info.button.receive-new-letter',
                  'I want to receive exclusive offers via emails from {company}',
                  {
                    company: props.branch.company.name,
                  }
                )}
              />
            </FormLayout>
          </div>
        </CardContent>

        <CardFooter>
          <PrimaryButton onClick={handleValidateAndSubmit} className="bg-indigo-600 px-8 hover:bg-indigo-400">
            {t('storefront-appointment.create.contact-info.button.submit', 'Submit')}
          </PrimaryButton>
        </CardFooter>
      </Card>
    </div>
  );
}
