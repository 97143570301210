import { DaysOfWeek } from '@app/graphql/types/graphql.ts';

export function todayIs() {
  const daysOfWeeks: DaysOfWeek[] = [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
  ];
  const date = new Date();
  return daysOfWeeks[date.getDay()];
}
